import {NxLoader} from '@nextbank/ui-components';
import {isNil} from 'lodash';
import React, {ReactElement, useContext, useState} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import {DictionaryCode, DictionaryEntryGeolocationLevel} from '../../../../../constants/dictionary-code';
import useDictionaryEntriesByCode from '../../../../../shared/hooks/use-dictionary-entries-by-code.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {Phase} from '../../../../../shared/model/phase.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {DraggableCheckGroup} from '../../../shared/draggable-checks/draggable-checks.model';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {useSetCheckGroupsFromConfig} from '../hooks/use-set-check-groups-from-config';
import EligibilityCriteriaForm from './EligibilityCriteriaForm';

const EligibilityCriteria = (): ReactElement => {

  const {process, configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.ELIGIBILITY);
  const [checkGroups, setCheckGroups] = useState<DraggableCheckGroup[]>([]);
  const config = useSingleQuery<Phase>(phaseUrl);
  // TODO
  const provinceEntries = useDictionaryEntriesByCode(DictionaryCode.GEOLOCATION)?.filter(
    entry => entry.level === DictionaryEntryGeolocationLevel.PROVINCE
  );

  useSetCheckGroupsFromConfig(config, setCheckGroups);

  const updateCheckGroups = (checkGroups: DraggableCheckGroup[]): void => {
    if (!configurationChanged) {
      setConfigurationChanged(true);
    }

    setCheckGroups(checkGroups);
  };

  return isNil(config) || isNil(provinceEntries) ?
    <NxLoader /> :
    <EligibilityCriteriaForm config={config}
                             provinceEntries={provinceEntries}
                             checkGroups={checkGroups}
                             updateCheckGroups={updateCheckGroups} />;
};

export default EligibilityCriteria;
