import {NxSelectOption} from '@nextbank/ui-components';
import {isNil, orderBy} from 'lodash';
import {Category} from '../shared/model/category.model';
import {Dictionary, DictionaryEntry} from '../shared/model/dictionary.model';
import {addParentsToCategoryName} from './category-utils';
import {DictionaryHelper} from './dictionary-helper';

interface Entry {
  id: number;
  name: string;
  enabled?: boolean;
}

const mapToStringOptions = (entries: Entry[] = []): NxSelectOption[] =>
  mapToNumberOptions(entries).map(option => ({...option, value: String(option.value)}));

const mapToNumberOptions = (entries: Entry[] = []): NxSelectOption<number>[] =>
  entries
    .sort(((entryA, entryB) => (entryA.name < entryB.name ? -1 : 1)))
    .map(entry => ({value: entry.id, label: entry.name, disabled: !isNil(entry.enabled) && !entry.enabled}));

const toLabelArray = (entries: Entry[] = []): string[] =>
  entries.map(entry => entry.name);

export const SelectHelper = {

  mapToStringOptions,

  mapToNumberOptions,

  toLabelArray,

  mapDictionaryToOptions: (dictionaries?: Dictionary[], dictionaryId?: number): NxSelectOption[] | undefined => {
    if (!dictionaries || !dictionaryId) {
      return;
    }

    const entries = DictionaryHelper.getEntries(dictionaries, dictionaryId);

    return mapToStringOptions(entries);
  },

  mapCategoriesToOptions: (categories: Category[]): NxSelectOption<number>[] => {

    const categoriesWithParentNames = addParentsToCategoryName(categories);

    // TODO sort categories on FE by orderNo not names
    return mapToNumberOptions(orderBy(categoriesWithParentNames, ['name']) as DictionaryEntry[]);
  }
};

