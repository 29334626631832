export enum DictionaryCode {
  COUNTRY = 'SYS_COUNTRY',
  PROVINCE = 'SYS_PROVINCE',
  GEOLOCATION = 'SYS_GEOLOCATION',
  NATURE_OF_WORK_OR_BUSINESS = 'SYS_NATURE_OF_WORK_BUSINESS',
  NATURE_TYPE = 'CBS_NOWB_TYPE',
  BUSINESS_TYPE = 'CBS_NOWB_BUSINESS_TYPE',
  EMPLOYER_TYPE = 'SYS_EMPLOYER_TYPE'
}

export enum DictionaryEntryGeolocationLevel {
  PROVINCE = 2,
  CITY = 3,
  BARANGAY = 4
}
