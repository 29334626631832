import {Category} from '../shared/model/category.model';
import {cloneDeep, isNil} from 'lodash';

interface UpdatedCategory {
  index: number;
  parentId: number;
}

export const addParentsToCategoryName = (initialCategories?: Category[], addFirstChildName = true): Category[] => {

  let updatedCategories: UpdatedCategory[] = [];
  if (isNil(initialCategories)) {
    return [];
  }

  const categories = cloneDeep(initialCategories);

  const addParentName = (childIndex: number,  parentId?: number,  addFirstChildName = true): void => {
    if (!parentId) {
      return;
    }

    const parent = initialCategories.find(({id}) => id === parentId);

    if (!parent) {
      return;
    }

    const {name, parentCategoryId, id} = parent;

    // This checks the categories that have already been updated to prevent infinite loop
    if (updatedCategories.some(category => category.index === childIndex && category.parentId === id)) {
      return;
    }

    categories[childIndex].name = addFirstChildName ? `${name} > ${categories[childIndex].name}` : name;
    updatedCategories.push({index: childIndex, parentId})
    addParentName(childIndex, parentCategoryId);
  }

  categories.forEach((category, index) =>
    addParentName(index, category.parentCategoryId, addFirstChildName));

  return categories;
}
