import {NxSelectOption} from '@nextbank/ui-components';
import React, {useEffect, useState} from 'react';
import {NxFormikSelect} from '@nextbank/ui-components';
import useGet from '../../../shared/hooks/use-get.hook';
import styles from './ChangeBatchTypeForm.module.scss'
import {BatchTypeTO, fromBatchTypeTO} from '../batch.model';
import {PRE_APPROVED_TYPES_URL} from '../../../constants/api-urls';
import {SetFieldValue} from '../../../shared/model/formik.model';
import {TransHelper} from '../../../utils/trans-helper';

export const TRANS_PREFIX = 'BATCH';
export const CREATE_TRANS_PREFIX = `${TRANS_PREFIX}.CREATE`;
export const PrefixTrans = TransHelper.getPrefixedTrans(CREATE_TRANS_PREFIX);

interface Props {
  setFieldValue: SetFieldValue;
}

export const ChangeBatchTypeForm = ({setFieldValue}: Props): React.ReactElement => {

  const fetchBatchTypes = useGet<BatchTypeTO[]>(PRE_APPROVED_TYPES_URL);
  const [options, setOptions] = useState<NxSelectOption<string>[]>([]);

  useEffect(() => {
    fetchBatchTypes().then(batchTypesTO => {
      const batchTypes = batchTypesTO
        .map(fromBatchTypeTO);

      const batchTypeOptions = batchTypes.map(batchType => ({
        value: batchType.code,
        label: batchType.name
      }) as NxSelectOption<string>);

      setOptions(batchTypeOptions);
      if (batchTypeOptions.length === 1) {
        setFieldValue('code', batchTypeOptions[0].value)
      }
    });
  }, []);

  return <NxFormikSelect<string> className={styles.select}
              name='code'
              options={options}
              label={<PrefixTrans>BATCH_TYPE</PrefixTrans>}
              disabled={options.length <= 1} />  
};
