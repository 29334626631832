import {NxFormikCashInput, NxFormikCheckbox, NxFormikPercentageInput} from '@nextbank/ui-components';
import React, {ReactElement, useContext} from 'react';
import {SimulatedFee} from '../../../../../../../../shared/model/loan-simulation.model';
import {CashHelper} from '../../../../../../../../utils/cash-helper';
import {StringHelper} from '../../../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../../../utils/trans-helper';
import {StepContext} from '../../../loan-application-step/LoanApplicationStep';
import styles from './FeesOverrideTableBody.module.scss';

interface Props {
  fees: SimulatedFee[];
}

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.OVERRIDE_FEES');

export const FeesOverrideTableBody = ({fees}: Props): ReactElement => {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <>
      {
        fees.map((fee, index) => {
          const getName = (key: keyof SimulatedFee): string => `simulation.input.simulatedFees[${index}].${key}`;

          return (
            <>
              <div>{fee.feeName}</div>
              <div>
                {
                  fee.isFixed
                    ? <NxFormikCashInput label={<PrefixTrans>AMOUNT</PrefixTrans>}
                                         name={getName('originalFixedAmount')}
                                         disabled={true} />
                    : <NxFormikPercentageInput label={<PrefixTrans>AMOUNT</PrefixTrans>}
                                               name={getName('originalFixedAmount')}
                                               disabled={true} />
                }
              </div>
              <div>
                {
                  fee.isFixed
                    ? <NxFormikCashInput label={<PrefixTrans>AMOUNT</PrefixTrans>}
                                         name={getName('fixedAmount')}
                                         disabled={isStepReadonly} />
                    : <NxFormikPercentageInput disabled={isStepReadonly}
                                               name={getName('fixedAmount')}
                                               label={<PrefixTrans>AMOUNT</PrefixTrans>} />
                }
              </div>
              <div className={styles.difference}>
                {
                  fee.fixedAmount - fee.originalFixedAmount != 0
                    ? fee.isFixed 
                      ? CashHelper.addCurrencyMask(fee.fixedAmount - fee.originalFixedAmount) 
                      : StringHelper.addPercentage(fee.fixedAmount - fee.originalFixedAmount)
                    : '-'
                }
              </div>
              <div>
                <NxFormikCheckbox className={styles.alreadyPaid}
                                  name={getName('alreadyPaid')}
                                  disabled={isStepReadonly} />
              </div>
            </>
          );
        })
      }
    </>
  );
};
