import mapValues from 'lodash/mapValues';
import React, {ReactElement} from 'react';
import {LANDLINE_PHONE_NUMBER} from '../../../../../../../constants/format-templates';
import {DictionaryEntry} from '../../../../../../../shared/model/dictionary.model';
import {isAnyEnabled} from '../../../../../../../utils/empty-checks-utils';
import {SelectHelper} from '../../../../../../../utils/select-helper';
import {StringHelper} from '../../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import FormColumn from '../../../../../../shared/form-column/FormColumn';
import FormGroup from '../../../../../../shared/form-column/FormGroup';
import {IndividualDataFormFields} from '../../customer-data-form.model';
import {ConfiguredField} from '../../../../../../../shared/model/field.model';
import {
  ApplicantDataDatePicker as DatePicker,
  ApplicantDataFileInput as FileInput,
  ApplicantDataInput as Input,
  ApplicantDataPhoneInput as PhoneInput,
  ApplicantDataSelect as Select
} from '../applicant-data-inputs/IndividualDataInputs';
import styles from '../ApplicantlData.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.CUSTOMER_DATA.APPLICANT_DATA');
const {mapToStringOptions} = SelectHelper;

interface Props {
  values: IndividualDataFormFields;
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.individualData.firstName.
   */
  formikFieldPath: string;
  entries: {
    title?: DictionaryEntry[];
    gender?: DictionaryEntry[];
    residence?: DictionaryEntry[];
    citizenship?: DictionaryEntry[];
    riskLevel?: DictionaryEntry[];
    civilStatus?: DictionaryEntry[];
    dosriType?: DictionaryEntry[];
    birthCountry?: DictionaryEntry[];
  };
  extraNumberFieldValue: ConfiguredField
}

export default function IndividualData({values, formikFieldPath, entries, extraNumberFieldValue}: Props): ReactElement {

  const {
    firstName, lastName, middleName, titleId, nickname, extension, vip, pep, riskLevelId, dosri, dosriTypeId,
    bankEmployee, mothersFirstName, mothersMaidenName, mothersMiddleName, email, mobileNumber, workNumber,
    landlineNumber, genderId, civilStatusId, birthDate, birthPlace, birthCountryId, residenceId, citizenshipId, picture, signature
  } = values;

  const options = mapValues(entries, entryArray => mapToStringOptions(entryArray));

  const fieldPath = `${formikFieldPath}.individualData`;
  const getPath = (fieldName: string): string => `${fieldPath}.${fieldName}`;
  const getBorrowerPath = (fieldName: string): string => `${formikFieldPath}.${fieldName}`;

  return (
    <>
      <div className={styles.row}>
        <FormColumn>
          <FormGroup visible={isAnyEnabled([firstName, lastName, middleName, titleId, nickname, extension])}
                     header={<PrefixTrans>PERSONAL_DATA</PrefixTrans>}>
            <Input fieldKey={getPath('firstName')} value={firstName} />
            <Input fieldKey={getPath('lastName')} value={lastName} />
            <Input fieldKey={getPath('middleName')} value={middleName} />
            <Select fieldKey={getPath('titleId')} value={titleId} options={options.title} />
            <Input fieldKey={getPath('nickname')} value={nickname} />
            <Input fieldKey={getPath('extension')} value={extension} />
          </FormGroup>
          <FormGroup visible={isAnyEnabled([vip, pep, riskLevelId, dosri, bankEmployee, picture, signature])}
                     header={<PrefixTrans>COMPLIANCE</PrefixTrans>}>
            <Select fieldKey={getPath('vip')} value={vip} emptyOption={false} />
            <FileInput fieldKey={getPath('signature')} value={signature} />
            <Select fieldKey={getPath('pep')} value={pep} emptyOption={false} />
            <Select fieldKey={getPath('riskLevelId')} value={riskLevelId} options={options.riskLevel} />
            <Select fieldKey={getPath('dosri')} value={dosri} emptyOption={false} />
            {
              StringHelper.stringToBoolean(dosri.value) &&
              <Select fieldKey={getPath('dosriTypeId')} value={dosriTypeId} options={options.dosriType} />
            }
            <Select fieldKey={getPath('bankEmployee')} value={bankEmployee} emptyOption={false} />
            <FileInput fieldKey={getPath('picture')} value={picture} />
          </FormGroup>
          <FormGroup visible={isAnyEnabled([mothersFirstName, mothersMaidenName, mothersMiddleName])}
                     header={<PrefixTrans>RELATIVES</PrefixTrans>}>
            <Input fieldKey={getPath('mothersFirstName')} value={mothersFirstName} />
            <Input fieldKey={getPath('mothersMaidenName')} value={mothersMaidenName} />
            <Input fieldKey={getPath('mothersMiddleName')} value={mothersMiddleName} />
          </FormGroup>
        </FormColumn>
        <FormColumn>
          <FormGroup visible={isAnyEnabled([email, mobileNumber, workNumber, landlineNumber])}
                     header={<PrefixTrans>CONTACT_DATA</PrefixTrans>}>
            <Input fieldKey={getPath('email')} value={email} />
            <PhoneInput fieldKey={getPath('mobileNumber')} value={mobileNumber} />
            <PhoneInput fieldKey={getPath('workNumber')} value={workNumber} />
            <PhoneInput fieldKey={getPath('landlineNumber')} value={landlineNumber} mask={LANDLINE_PHONE_NUMBER} />
          </FormGroup>
          <FormGroup header={<PrefixTrans>DEMOGRAPHICS</PrefixTrans>}
                     visible={
                       isAnyEnabled([genderId, civilStatusId, birthDate, birthCountryId, birthPlace, residenceId, citizenshipId])
                     }>
            <Select fieldKey={getPath('genderId')} value={genderId} options={options.gender} />
            <Select fieldKey={getPath('civilStatusId')} value={civilStatusId} options={options.civilStatus} />
            <DatePicker fieldKey={getPath('birthDate')} value={birthDate} />
            <Select fieldKey={getPath('birthCountryId')} value={birthCountryId} options={options.birthCountry} />
            <Input fieldKey={getPath('birthPlace')} value={birthPlace} />
            <Select fieldKey={getPath('residenceId')} value={residenceId} options={options.residence} />
            <Select fieldKey={getPath('citizenshipId')} value={citizenshipId} options={options.citizenship} />
          </FormGroup>
          <FormGroup header={<PrefixTrans>THIRD_PARTY_SYSTEM_DATA</PrefixTrans>}
                     visible={
                       isAnyEnabled([extraNumberFieldValue])
                     }>
            <Input fieldKey={getBorrowerPath('extraNumber')} value={extraNumberFieldValue} />
          </FormGroup>
        </FormColumn>
      </div>
    </>
  );
}
